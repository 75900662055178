<template>
  <div>
    <w-top-bar>Detalles del Producto</w-top-bar>
    <div v-if="item" class="h-full p-5 bg-gray-200">
      <FeathersVuexFormWrapper :item="item" watch>
        <!-- <template v-slot="{ clone, save, reset, remove }"> -->
        <template v-slot="{ clone, save }">
          <div>
            <div class="flex w-full p-5 text-sm bg-white rounded-md shadow">
              <div class="w-2/5">
                <div class="text-lg font-medium">Información General</div>
                <div class="text-gray-700">
                  La información se mostrara en la página de promoción del
                  producto.
                </div>
              </div>
              <div class="flex-grow">
                <w-input label="Nombre" v-model="clone.name" />
                <w-input label="SKU" v-model="clone.sku" />
                <w-input-number label="Stock" v-model="clone.stock" />
                <!-- <w-shorter-input label="Precio Dolares" v-model="clone.shorter" /> -->
                <w-html label="Descripción" v-model="clone.texto" />
                <w-html label="Intro" v-model="clone.extra.descripcion" />
                <w-image-upload
                  v-model="clone.image"
                  @input="clone.cloudImage = $event"
                  class="my-5"
                  label="Imágen Presentación"
                />
                <w-category-select
                  :initial="clone.type"
                  class="mt-5"
                  v-model="clone.types"
                ></w-category-select>
                <w-input label="Genero" v-model="clone.genero" />
                <!-- <w-select
                  label="Tipo de Producto"
                  v-model="clone.type"
                  :options="categories"
                /> -->
                <div class="mt-5">
                  <w-switch label="Público" v-model="clone.isPublic"></w-switch>
                  <div class="text-sm text-gray-500 md:text-normal">
                    El producto estara visible en la página y disponible para
                    comprar.
                  </div>
                </div>
                <div class="mt-5">
                  <w-switch label="Oculto" v-model="clone.hidden"></w-switch>
                  <div class="text-sm text-gray-500 md:text-normal">
                    El producto está activo, pero no se muestra en los listados.
                  </div>
                </div>
              </div>
            </div>
            <div
              class="flex w-full p-5 mt-5 text-sm bg-white rounded-md shadow"
            >
              <div class="w-2/5 pr-4">
                <div class="text-lg font-medium">Detalles de Venta</div>
                <div class="text-gray-700">
                  Configura los detalles para la venta del producto.
                </div>
              </div>
              <div class="w-4/5">
                <!-- Only for streamings -->
                <!-- <w-date-time
                  label-day="Fecha del Producto"
                  label-hour="Horario de comienzo"
                  v-model="clone.date"
                  :duration="clone.duration"
                  @duration="clone.duration = $Product"
                /> -->

                <w-product-price
                  label="Precio"
                  v-model="clone.price"
                ></w-product-price>

                <div class="mt-5">
                  <w-switch
                    label="Complementar con Libro"
                    v-model="clone.hasMany"
                  ></w-switch>
                  <div class="text-sm text-gray-500 md:text-normal">
                    Si está activa, se mostrará un listado de libros para
                    agregar al evento (no se suma el costo del libro).
                  </div>
                </div>
                <div v-if="clone.hasMany">
                  <w-category-select
                    :initial="clone.hasManyCategories"
                    class="mt-3"
                    v-model="clone.hasManyCategories"
                  ></w-category-select>
                </div>

                <div>
                  <div class="mt-5">
                    <w-switch
                      label="Prelanzamiento"
                      v-model="clone.prelaunch"
                    ></w-switch>
                    <div class="text-sm text-gray-500 md:text-normal">
                      Selecciona esta opción si el producto esta en
                      <strong>prelanzamiento</strong>.
                    </div>
                  </div>
                  <div v-if="clone.prelaunch" class="mt-5">
                    <!-- Checkbox para fechaIndefinida -->
                    <w-switch
                      label="Fecha Indefinida"
                      v-model="clone.fechaIndefinida"
                    ></w-switch>
                    <div class="text-sm text-gray-500 md:text-normal mb-2">
                      Si esta activo, no se mostrará la fecha de lanzamiento
                    </div>

                    <w-date-time
                      v-if="!clone.fechaIndefinida"
                      label-day="Fecha de Lanzamiento"
                      v-model="clone.date"
                    />
                    <w-html
                      label="Descripción del prelanzamientos"
                      description="Se muestra arriba del botón Agregar al Carrito"
                      v-model="clone.prelaunchDescription"
                    />
                    <w-html
                      label="Mensaje de Conlicto en el Carrito (HTML)"
                      description="Se muestra en la ventana emergente de alerta de conflicto con el carrito. (No se permiten productos en prelanzamiento y en stock a la vez)"
                      v-model="clone.aloneHtml"
                    />
                  </div>
                  <div class="mt-5">
                    <w-switch
                      label="¿Tiene Variantes?"
                      v-model="clone.hasVariants"
                    ></w-switch>
                    <div class="text-sm text-gray-500 md:text-normal">
                      Selecciona esta opción si quieres agregar variantes al
                      producto.
                    </div>
                  </div>
                  <w-textarea
                    class="mt-5"
                    description="Agregaremos instrucciones especificas en la pagina 'gracias por tu pago'"
                    label="Instrucciones Post-Venta (HTML)"
                    v-model="clone.instructionThankyouPage"
                  />
                </div>
              </div>
            </div>
            <div
              class="flex w-full p-5 mt-5 text-sm bg-white rounded-md shadow"
            >
              <div class="w-2/5">
                <div class="text-lg font-medium">Personalización</div>
                <div class="text-gray-700">
                  Solicitar datos extra si el producto está en el carrito.
                </div>
              </div>
              <div class="w-3/5">
                <div class="flex justify-end">
                  <button
                    @click="clone.customs.push({})"
                    class="flex items-center px-4 py-2 text-xs font-normal transition duration-150 ease-in-out rounded"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="w-6 h-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                    Agregar
                  </button>
                </div>
                <div
                  v-for="(item, index) in clone.customs"
                  :key="index"
                  class="relative flex-grow p-2 mb-2 border rounded shadow-sm"
                >
                  <div
                    class="absolute top-0 right-0 flex items-center gap-1 mx-1 my-2"
                  >
                    <span class="text-xs text-gray-500">#{{ index + 1 }}</span>
                    <a
                      v-tooltip="'Eliminar item'"
                      @click="remove(clone, index)"
                      type="button"
                      class="text-red-600 cursor-pointer opacity-20 hover:opacity-100"
                    >
                      <svg
                        class="w-5 h-5 mr-2"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                        />
                      </svg>
                    </a>
                  </div>
                  <w-input label="Nombre" v-model="item.title" />
                  <w-html label="HTML" v-model="item.html" />
                  <w-input label="Por defecto" v-model="item.default" />
                  <div>
                    <w-select
                      label="Tipo de campo"
                      class="mb-2"
                      :show-label="true"
                      v-model="item.type"
                      :options="fieldTypes"
                    />
                  </div>

                  <div v-if="item.chooseProducts">
                    <w-category-select
                      :initial="item.showCategories"
                      class="mt-3"
                      v-model="item.showCategories"
                    ></w-category-select>
                  </div>
                  <div v-else>
                    <w-input label="Limite de palabras" v-model="item.size" />
                  </div>
                  <div>
                    <w-switch
                      label="Mostrar productos como opción"
                      v-model="item.chooseProducts"
                    ></w-switch>

                    <w-tags
                      class="mt-3"
                      label="Válido para cupones"
                      v-model="item.validForDiscounts"
                    />

                    <w-switch
                      label="Oculto para todos"
                      v-model="item.hidden"
                    ></w-switch>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="flex w-full p-5 mt-5 text-sm bg-white rounded-md shadow"
            >
              <div class="w-2/5">
                <div class="text-lg font-medium">Detalles del Evento</div>
                <div class="text-gray-700">
                  Configura los detalles para la venta del producto.
                </div>
              </div>
              <div class="flex-grow">
                <!-- Only for streamings -->
                <div class="my-5">
                  <w-switch
                    label="¿Tiene Fecha?"
                    v-model="clone.hasDate"
                  ></w-switch>
                  <div class="text-sm text-gray-500 md:text-normal">
                    Selecciona esta opción si quieres agregar una fecha al
                    producto.
                  </div>
                </div>
                <div class="my-5">
                  <w-switch
                    label="Envío Gratis"
                    v-model="clone.freeShipping"
                  ></w-switch>
                  <div class="text-sm text-gray-500 md:text-normal">
                    Aparecerá una opción de envio gratuito en la pasarela de
                    compras.
                  </div>
                </div>
                <div class="my-5">
                  <w-switch
                    label="Solo Retiro"
                    v-model="clone.soloRetiro"
                  ></w-switch>
                  <div class="text-sm text-gray-500 md:text-normal">
                    Este producto solo se puede retirar en el local.
                  </div>
                </div>
                <w-date-time
                  v-if="clone.hasDate"
                  label-day="Fecha del Evento"
                  label-hour="Horario de comienzo"
                  v-model="clone.date"
                  :duration="clone.duration"
                  @duration="clone.duration = $Product"
                />
              </div>
            </div>
            <div
              v-if="clone.hasVariants"
              class="flex w-full p-5 mt-5 text-sm bg-white rounded-md shadow"
            >
              <div class="w-2/5">
                <div class="text-lg font-medium">Variantes del Producto</div>
                <div class="text-gray-700">
                  Agrega opciones para personalizar el producto.
                </div>
              </div>
              <div class="flex-grow">
                <w-product-variant v-model="clone.variants" />
              </div>
            </div>
            <div
              class="flex w-full p-5 mt-5 text-sm bg-white rounded-md shadow"
            >
              <div class="w-2/5">
                <div class="text-lg font-medium">Datos Extra</div>
                <div class="text-gray-700">
                  <!-- Configura los costos de envío, tipos y tiempos de entrega. -->
                  Configuración campos extra, como por ejemplo ISBN, Autores,
                  etc.
                </div>
              </div>
              <div class="w-3/5">
                <w-input
                  description="Código internacional de producto"
                  label="ISBN/UPC"
                  v-model="clone.upc"
                />
                <w-author-select label="Autores" v-model="clone.authors" />
                <w-input label="Idioma" v-model="clone.language" />
                <w-input label="Editorial" v-model="clone.editorial" />
                <w-input
                  label="Formato"
                  description="Libro Físico, Libro Digital, etc"
                  v-model="clone.format"
                />
                <div class="flex gap-2">
                  <w-input
                    label="Ancho"
                    description="En centímetros"
                    v-model="clone.size.width"
                  />
                  <w-input
                    label="Alto"
                    description="En centímetros"
                    v-model="clone.size.height"
                  />
                  <w-input
                    label="Largo"
                    description="En centímetros"
                    v-model="clone.size.length"
                  />
                </div>
                <div class="flex gap-2">
                  <w-input
                    class="w-1/2"
                    label="Peso"
                    description="En gramos"
                    v-model="clone.size.weight"
                  />
                  <div class="w-1/2">
                    <w-input
                      class="w-100"
                      label="Peso volumétrico"
                      v-model="clone.size.volumetric"
                    />
                    <div
                      v-if="
                        clone.size.width &&
                        clone.size.height &&
                        clone.size.length
                      "
                      class="w-full -mt-3 text-xs text-right"
                    >
                      <a
                        @click="
                          clone.volumetric =
                            (clone.size.width *
                              clone.size.height *
                              clone.size.length) /
                            5000
                        "
                        class="text-blue-600 underline cursor-pointer"
                        >Calcular peso</a
                      >
                    </div>
                  </div>
                </div>
                <!-- <div v-if="clone.size.width && clone.size.height && clone.size.length" class="w-full text-xs text-right">
                    <a class="text-blue-600 underline cursor-pointer" >Calcular Volumétrico</a>
                  </div> -->

                <div class="pt-2 mt-5 border-t">
                  <div class="text-right">
                    <a
                      class="text-xs text-blue-600 cursor-pointer"
                      @click="clone.customFields.push({ key: '', value: '' })"
                      >Agregar campo personalizado</a
                    >
                  </div>
                  <div
                    v-if="clone.customFields && clone.customFields.length > 0"
                  >
                    <div
                      class="flex w-full gap-2"
                      v-for="(cf, i) in clone.customFields"
                      :key="i"
                    >
                      <w-input
                        class="w-1/2"
                        :label="'Campo Personalizado #' + (i + 1)"
                        v-model="clone.customFields[i].key"
                      />
                      <w-input
                        class="w-1/2"
                        label="Valor"
                        v-model="clone.customFields[i].value"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="flex w-full p-5 mt-5 text-sm bg-white rounded-md shadow"
            >
              <div class="w-2/5">
                <div class="text-lg font-medium">Stock Personalizado</div>
                <div class="text-gray-700">
                  Descontar stock de otros productos al realizar una venta de
                  este producto.
                </div>
              </div>
              <div class="w-3/5">
                <div class="flex justify-end">
                  <button
                    @click="
                      clone.stockExtra.push({
                        productId: null,
                        stock: 1,
                      })
                    "
                    class="flex items-center px-4 py-2 text-xs font-normal transition duration-150 ease-in-out rounded"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="w-6 h-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                    Agregar
                  </button>
                </div>
                <div
                  v-for="(item, index) in clone.stockExtra"
                  :key="index"
                  class="relative flex-grow p-2 mb-2 border rounded shadow-sm"
                >
                  <div class="flex items-center gap-1 mx-1 my-2">
                    <div class="text-xs text-gray-500">#{{ index + 1 }}</div>
                    <!-- Select de producto largo -->
                    <w-select
                      class="w-5/6"
                      label="Producto"
                      v-model="clone.stockExtra[index].productId"
                      :options="productsOptions"
                    />
                    <div>
                      <input
                        label="Stock"
                        type="number"
                        class="w-24 text-center py-1 pl-6 text-sm border rounded shadow-sm"
                        v-model="clone.stockExtra[index].stock"
                      />
                    </div>
                    <div
                      class="flex items-center gap-1 mx-1 my-2 justify-right"
                    >
                      <a
                        v-tooltip="'Eliminar item'"
                        @click="clone.stockExtra.splice(index, 1)"
                        type="button"
                        class="text-red-600 cursor-pointer opacity-20 hover:opacity-100"
                      >
                        <svg
                          class="w-5 h-5 mr-2"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                          />
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              v-if="true"
              class="flex w-full p-5 mt-5 text-sm bg-white rounded-md shadow"
            >
              <div class="w-2/5">
                <div class="text-lg font-medium">Envíos</div>
                <div class="text-gray-700">
                  <!-- Configura los costos de envío, tipos y tiempos de entrega. -->
                  Configuración de los envíos.
                </div>
              </div>
              <div class="w-3/5">
                <div class="flex flex-col py-2 space-y-2">
                  <label class="inline-flex items-center">
                    <div class="">
                      <w-switch
                        label="Formato Digital"
                        v-model="clone.digital"
                      ></w-switch>
                      <div class="text-sm text-gray-500 md:text-normal">
                        Selecciona esta opción si el producto es en formato
                        digital.
                      </div>
                    </div>
                  </label>
                  <div v-if="!clone.digital">
                    <!-- <label class="inline-flex items-center">
                      <div class="">
                        <w-switch
                          label="Envío Internacional (DHL)"
                          v-model="clone.dhl"
                        ></w-switch>
                        <div class="text-sm text-gray-500 md:text-normal">
                          Ofrece envíos internacionales para este producto
                        </div>
                      </div>
                    </label>
                    <div v-if="clone.dhl" class="flex flex-wrap mt-4">
                      <div class="w-1/2 pr-5">
                        <w-input-price-v2
                          label="Precio DHL"
                          v-model="clone.dhlPrice"
                        ></w-input-price-v2>
                      </div>
                      <div class="w-1/2 pr-5">
                        <w-select
                          label="Tiempo de envío"
                          v-model="clone.dhlTime"
                          :options="deliveryTimes"
                        />
                      </div>
                    </div> -->
                    <!-- <label class="inline-flex items-center mt-3">
                      <div class="">
                        <w-switch
                          label="Argentina"
                          v-model="clone.shippingArgentina"
                        ></w-switch>
                        <div class="text-sm text-gray-500 md:text-normal">
                          Ofrece envíos en Argentina
                        </div>
                      </div>
                    </label>
                    <div
                      v-if="clone.shippingArgentina"
                      class="flex flex-wrap mt-4"
                    >
                      <div class="w-1/2 pr-5">
                        <w-input-price-v2
                          label="Precio Envío"
                          currency="ars"
                          v-model="clone.shippingArgentinaPrice"
                        ></w-input-price-v2>
                      </div>
                      <div class="w-1/2 pr-5">
                        <w-select
                          label="Tiempo de envío"
                          v-model="clone.shippingArgentinaTime"
                          :options="deliveryTimes"
                        />
                      </div>
                    </div>
                    <label class="inline-flex items-center mt-3">
                      <div class="">
                        <w-switch
                          label="Mercado Libre"
                          v-model="clone.meli"
                        ></w-switch>
                        <div class="text-sm text-gray-500 md:text-normal">
                          Redireccionar compras de CABA a Mercado Libre
                        </div>
                      </div>
                    </label>
                    <div v-if="clone.meli">
                      <w-input
                        description="Enlace de Mercado Libre"
                        v-model="clone.meliLink"
                      />
                    </div> -->
                  </div>
                </div>
              </div>
            </div>

            <div
              v-if="clone.type == 'streamings' || true"
              class="flex w-full p-5 mt-5 text-sm bg-white rounded-md shadow"
            >
              <div class="w-2/5">
                <div class="text-lg font-medium">Enlace Directo</div>
                <div class="text-gray-700">
                  Enlace directo a la página del producto.
                </div>
              </div>
              <div class="flex-grow">
                <div
                  ref="container"
                  class="relative flex items-center flex-grow p-2 text-xs font-medium text-indigo-900 bg-indigo-400 bg-opacity-50 rounded"
                >
                  <div>
                    {{ directLink }}
                  </div>
                  <svg
                    v-if="!copied"
                    v-tooltip="'Copiar dirección al portapapeles'"
                    @click="copyDirectLink()"
                    class="absolute w-5 h-5 cursor-pointer right-2 hover:text-indigo-600"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M8 5H6a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2v-1M8 5a2 2 0 002 2h2a2 2 0 002-2M8 5a2 2 0 012-2h2a2 2 0 012 2m0 0h2a2 2 0 012 2v3m2 4H10m0 0l3-3m-3 3l3 3"
                    />
                  </svg>
                  <svg
                    v-else
                    class="absolute w-5 h-5 cursor-pointer right-2"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </div>
                <div
                  v-if="!editPermalink"
                  class="mt-1 text-xs underline cursor-pointer text-marine-500"
                  @click="editPermalink = true"
                >
                  Editar enlace permanente
                </div>
                <w-input
                  v-if="editPermalink"
                  label="Enlace Permanente"
                  description="Solo caracteres y guiones, sin espacios."
                  class="mt-3"
                  v-model="clone.key"
                />
              </div>
            </div>

            <!-- <div
              v-if="clone.type == 'streamings'"
              class="flex w-full p-5 mt-5 text-sm bg-white rounded-md shadow"
            >
              <div class="w-2/5">
                <div class="text-lg font-medium">Personalizar</div>
                <div class="text-gray-700">
                  Personaliza mensajes y otros detalles
                </div>
              </div>
              <div class="flex-grow">
                <w-input
                  label="Texto para Agregar al Carrito"
                  v-model="clone.addToCartText"
                />
              </div>
            </div> -->
            <div class="flex justify-end py-2">
              <div
                v-if="saved"
                class="flex items-center text-sm font-medium text-green-600"
              >
                <div>Cambios guardados</div>
                <svg
                  class="w-5 h-5 ml-1 mr-2 font-normal text-green-600 transition duration-150 ease-in-out"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </div>
              <div
                v-if="saving"
                class="flex items-center text-sm font-medium text-gray-600"
              >
                <div>Guardando cambios</div>

                <svg
                  class="w-5 h-5 ml-1 mr-2 font-normal text-gray-600 transition duration-150 ease-in-out animate-spin"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
                  />
                </svg>
              </div>
              <button
                @click="doSave(save)"
                class="flex items-center px-4 py-2 text-sm font-bold text-white transition duration-150 ease-in-out bg-green-500 rounded hover:bg-green-700"
              >
                Guardar
              </button>
            </div>
          </div>
        </template>
      </FeathersVuexFormWrapper>
    </div>
  </div>
</template>

<script>
import { FeathersVuexFormWrapper, makeFindMixin } from "feathers-vuex";

export default {
  name: "EditProduct",
  components: {
    FeathersVuexFormWrapper,
  },
  mixins: [
    makeFindMixin({ service: "categories" }),
    makeFindMixin({ service: "products" }),
  ], // Step 2
  computed: {
    productsParams() {
      return {
        query: {
          $limit: 300,
          $select: ["sku", "name", "_id"],
          $sort: { sku: 1 },
        },
      };
    },
    productsOptions() {
      return [
        {
          label: "Seleccionar Producto",
          value: null,
        },
        ...this.products.map((p) => {
          return {
            key: p._id,
            label: p.sku + " - " + p.name,
          };
        }),
      ];
    },
    id() {
      return this.$route.params.id;
    },
    // Returns a new Todo if the route `id` is 'new', or returns an existing Todo.
    item() {
      const { Product } = this.$FeathersVuex.api;

      return this.id === "new" ? new Product() : Product.getFromStore(this.id);
    },
    productTypeUrl() {
      let type = this.item.type;

      if (this.item.types && this.item.types.length > 0)
        type = this.item.types[0];

      if (type) return type;
      return "libros";
      // console.log("this.item.type", this.item.type);
      // if (type === "books") return "libros";
      // if (type === "streamings") return "streamings";
      // if (type === "magazines" || type === "magazine") return "revistas";
      // return "libros";
    },
    directLink() {
      if (!this.item) return null;
      return (
        "https://tienda.orsai.org/" + this.productTypeUrl + "/" + this.item.key
      );
    },
    categoriesParams() {
      return {
        query: {
          $sort: {
            name: -1,
          },
        },
      };
    },
  },
  watch: {
    id: {
      handler(val) {
        // Early return if the route `:id` is 'new'
        if (val === "new") {
          return;
        }
        const { Product } = this.$FeathersVuex.api;
        const existingRecord = Product.getFromStore(val);

        // If the record doesn't exist, fetch it from the API server
        // The `item` getter will automatically update after the data arrives.
        if (!existingRecord) {
          Product.get(val);
        }
      },
      // We want the above handler handler to run immediately when the component is created.
      immediate: true,
    },
  },
  data() {
    return {
      customs: [],
      fieldTypes: [
        { key: "number", label: "Número" },
        { key: "text", label: "Texto" },
      ],
      editPermalink: false,
      saved: false,
      copied: false,
      saving: false,
      // productTypes: [
      //   { key: "books", label: "Libro", shipping: true },
      //   { key: "ebook", label: "Libro Digital" },
      //   { key: "magazine", label: "Revista", shipping: true },
      //   { key: "streamings", label: "Evento Streaming" },
      // ],

      deliveryTimes: [
        { key: 0, label: "En el día" },
        { key: "3-days", label: "1-3 Días" },
        { key: "1-week", label: "1 Semana" },
        { key: "2-week", label: "15 Días" },
        { key: "4-week", label: "15-30 Días" },
      ],
    };
  },
  methods: {
    remove(product, index) {
      product.customs.splice(index, 1);
    },
    calcVolumetric(product) {
      product.volumetric = 100;
      console.log(product);
      this.$nextTick();
    },
    copyDirectLink() {
      let container = this.$refs.container;
      this.$copyText(this.directLink, container);
      this.copied = true;
      const self = this;
      setTimeout(() => {
        self.copied = false;
      }, 2000);
    },
    async doSave(save) {
      this.saved = false;
      this.saving = true;
      let product = await save();
      this.saving = false;
      this.saved = true;
      const self = this;
      setTimeout(() => {
        self.saved = false;
      }, 3000);
      this.handleSaveReponse(product);
    },
    handleSaveReponse(savedProduct) {
      // Redirect to the newly-saved item
      console.log("savedProduct", savedProduct);
      console.log("handleSaveReponse");
      if (this.id === "new") {
        this.$router.push({ params: { id: savedProduct._id } });
      }
    },
  },
};
</script>

<style lang="css"></style>
