<template>
  <div class="mb-2">
    <label
      for="input"
      class="block mb-1 text-sm font-medium leading-5 text-gray-700"
      >Categorias</label
    >
    <div v-if="categories && categories.length > 0">
      <multiselect
        ref="categories"
        label="label"
        track-by="label"
        key="categories"
        v-model="selected"
        :multiple="true"
        :options="categories"
        placeholder="País"
        selected-label=""
        select-label=""
        deselect-label=""
      >
        <template slot="singleLabel" slot-scope="{ option }">
          <div class="flex items-center space-x-2 text-sm">
            <div class="">
              {{ option.label }}
            </div>
          </div>
        </template>
        <template slot="option" slot-scope="{ option }">
          <div v-if="option" class="flex items-center space-x-2 text-sm">
            <div class="">
              {{ option.label }}
            </div>
          </div>
        </template>
        <template slot="placeholder">
          <div class="text-xs">Seleccionar Categoria</div>
        </template>
      </multiselect>
    </div>
    <div class="text-xs text-gray-600">{{ description }}</div>
  </div>
</template>

<script>
import { makeFindMixin } from "feathers-vuex";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
export default {
  components: {
    Multiselect,
  },
  props: {
    value: {
      type: [Array],
      default: null,
    },
    initial: {
      type: [String, Array],
      default: null,
    },
    label: {
      type: String,
      default: "Paises",
    },
    description: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      countries: [],
      selected: null,
      detected: null,
    };
  },
  mixins: [makeFindMixin({ service: "categories" })], // Step 2
  computed: {
    categoriesParams() {
      return {
        query: {
          $sort: {
            name: -1,
          },
        },
      };
    },
  },
  watch: {
    categories: {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          let selected = [];
          if (Array.isArray(this.value) && this.value.length > 0) {
            this.value.forEach((item) => {
              let ct = newValue.find((it) => {
                return it.key === item;
              });
              if (ct) selected.push(ct);
            });
          } else {
            console.log("find", this.initial);
            let ct = newValue.find((it) => {
              return it.key === this.initial;
            });
            if (ct) selected.push(ct);
          }

          this.selected = selected;
        }
      },
    },
    selected(newValue) {
      if (newValue) {
        this.$emit(
          "input",
          newValue.map((v) => v.key)
        );
      }
    },
  },
  mounted() {},
};
</script>

<style lang="css">
.multiselect__input::placeholder {
  @apply text-sm;
}
</style>
