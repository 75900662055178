<template>
  <div class="mb-2" v-if="value">
    <w-input label="Nombre Variante" @input="update()" v-model="value.name" />
    <div>
      <div v-for="(item, index) in value.items" :key="index" class="flex items-center">
        <div class="relative flex-grow p-2 mb-2 border rounded shadow-sm border-marine-100">
          <div class="flex items-center gap-2">
            <w-input
              @input="update()"
              :id="'sku' + index"
              :label="'SKU ' + (index + 1)"
              v-model="item.sku"
              class="w-32"
            /><w-input-number
              @input="update()"
              :id="'stock' + index"
              :label="'Stock ' + (index + 1)"
              v-model="item.stock"
              class="w-16"
            />
            <w-input
              @input="update()"
              :id="'title' + index"
              :label="'Titulo ' + (index + 1)"
              v-model="item.name"
              class="flex-grow"
            />

            <w-image-upload-little
              :name="'product_variant_' + (index + '')"
              :keyId="index"
              v-model="item.image"
              @input="item.cloudImage = $event"
            />
          </div>
        </div>
        <button
          v-tooltip="'Eliminar opción'"
          @click="remove(index)"
          type="button"
          class="mx-1 my-2 text-red-600 cursor-pointer opacity-20 hover:opacity-100"
        >
          <svg
            class="w-5 h-5 mr-2"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
            />
          </svg>
        </button>
      </div>
    </div>
    <button
      @click="add()"
      class="flex items-center px-4 py-2 text-xs font-bold text-white transition duration-150 ease-in-out bg-green-500 rounded hover:bg-green-700"
    >
      Agregar opción
    </button>
  </div>
</template>

<script>
export default {
  // value = {}
  props: {
    value: {
      type: Object,
      default: () => {
        return {
          name: "",
          items: [],
        };
      },
    },
  },
  data() {
    return {
      variant: {},
    };
  },
  methods: {
    add() {
      this.value.items.push({
        name: "",
        price: {},
        image: null,
      });
    },
    remove(index) {
      if (index > -1) {
        this.value.items.splice(index, 1);
      }
    },
    update() {
      this.$emit("input", this.value);
    },
  },
};
</script>

<style lang="scss" scoped></style>
