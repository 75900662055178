<template>
  <validation-provider
    :rules="rules"
    v-slot="{ errors }"
    tag="label"
    class="block"
    :name="name"
  >
    <label
      :class="{ 'text-red-500': errors[0] }"
      for="input"
      class="block text-sm font-medium leading-5 text-gray-700"
      >{{ label }}</label
    >
    <currency-input
      v-model="innerValue"
      @focus="show = true"
      @blur="show = false"
      :name="name"
      locale="es"
      :disabled="disabled"
      :currency="currency"
      :class="{ 'border-red-500': errors[0], 'opacity-50': disabled }"
      class="block w-full px-2 py-1 mt-1 border rounded ring-1 ring-black ring-opacity-5 boder-gray-400 form-input sm:text-sm sm:leading-5"
      :placeholder="placeholder"
    />
    <div v-if="errors[0]" class="text-xs text-red-600">{{ errors[0] }}</div>
    <div v-else class="text-xs text-gray-600">{{ helperText }}</div>
  </validation-provider>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      default: null,
    },
    dolar: {
      type: Number,
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
    rules: {
      type: String,
      default: () => "required",
    },
    label: {
      type: String,
      default: null,
    },
    helper: {
      type: String,
      default: null,
    },
    currency: {
      type: String,
      default: "ars",
    },
    inputClass: {
      type: String,
      default: "ars",
    },
    placeholder: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      innerValue: null,
      show: false,
    };
  },
  watch: {
    innerValue() {
      this.$emit("input", this.innerValue);
    },
  },
  computed: {
    dollarHelper() {
      // if (this.dolar) return `La cotización del dia es de 1 USD = ${this.dolar} ARS`;
      return null;
    },
    helperText() {
      if (this.helper) {
        console.log("hay helper", this.helper);
      }
      if (this.dollarHelper) return this.dollarHelper;
      return null;
    },
  },
  mounted() {
    this.innerValue = this.value;
  },
};
</script>

<style lang="scss" scoped></style>
