<template>
  <div class="">
    <div>
      <label
        v-if="showLabel"
        id="listbox-label"
        class="block text-sm font-medium text-gray-700"
      >
        {{ label }}
      </label>
      <div class="relative">
        <span
          class="inline-block w-full rounded-md shadow-sm"
          @click="open = true"
        >
          <button
            @click="open = false"
            type="button"
            aria-haspopup="listbox"
            aria-expanded="true"
            aria-labelledby="listbox-label"
            class="
              relative
              w-full
              py-1
              pl-3
              pr-10
              text-left
              transition
              duration-150
              ease-in-out
              bg-white
              border border-gray-300
              rounded-md
              cursor-default
              focus:outline-none focus:ring-blue focus:border-blue-300
              sm:text-sm sm:leading-5
            "
          >
            <div v-if="selected">
              <div class="flex items-center space-x-3">
                <img
                  v-if="selected.image"
                  src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                  alt=""
                  class="flex-shrink-0 w-6 h-6 rounded-full"
                />
                <span class="block truncate">
                  {{ selected.label }}
                </span>
              </div>
              <div
                v-if="selected.sublabel && false"
                class="block text-xs truncate"
              >
                {{ selected.sublabel }}
              </div>
            </div>
            <div v-else class="flex items-center space-x-3">
              <span class="block truncate">
                {{ emptyLabel || "Selecciona una opción" }}
              </span>
            </div>
            <span
              class="
                absolute
                inset-y-0
                right-0
                flex
                items-center
                pr-2
                pointer-events-none
              "
            >
              <svg
                class="w-5 h-5 text-gray-400"
                viewBox="0 0 20 20"
                fill="none"
                stroke="currentColor"
              >
                <path
                  d="M7 7l3-3 3 3m0 6l-3 3-3-3"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
          </button>
        </span>

        <!--
      Select popover, show/hide based on select state.

      Entering: ""
        From: ""
        To: ""
      Leaving: "transition ease-in duration-100"
        From: "opacity-100"
        To: "opacity-0"
    -->
        <div
          v-if="isOpen"
          class="absolute z-50 w-full mt-1 bg-white rounded-md shadow-lg"
        >
          <ul
            tabindex="-1"
            role="listbox"
            aria-labelledby="listbox-label"
            aria-activedescendant="listbox-item-3"
            class="
              py-1
              overflow-auto
              text-base
              leading-6
              rounded-md
              ring-1 ring-black ring-opacity-5
              max-h-56
              focus:outline-none
              sm:text-sm sm:leading-5
            "
          >
            <!--
          Select option, manage highlight styles based on mouseenter/mouseleave and keyboard navigation.

          Highlighted: "text-white bg-indigo-600", Not Highlighted: "text-gray-900"

            <li
              @click="select(null)"
              id="listbox-item-0"
              role="option"
              class="relative py-2 pl-3 text-gray-900 cursor-pointer select-none hover:bg-indigo-100 pr-9"
            >
              <div class="flex items-center space-x-3">
                <span class="block font-normal truncate"> Ver Todos </span>
              </div>
            </li> -->

            <li
              @click="select(item.key)"
              v-for="(item, index) in options"
              :key="index"
              id="listbox-item-0"
              role="option"
              class="
                relative
                py-2
                pl-3
                text-gray-900
                cursor-pointer
                select-none
                hover:bg-indigo-100
                pr-9
              "
            >
              <div>
                <div class="flex items-center space-x-3">
                  <img
                    v-if="item.image"
                    src="https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=facearea&amp;facepad=2&amp;w=256&amp;h=256&amp;q=80"
                    alt=""
                    class="flex-shrink-0 w-6 h-6 rounded-full"
                  />
                  <!-- Selected: "font-semibold", Not Selected: "font-normal" -->
                  <span class="block font-normal truncate">
                    {{ item.label }}
                  </span>
                </div>
                <div v-if="item.sublabel" class="block text-xs truncate">
                  {{ item.sublabel }}
                </div>
              </div>
              <!--
            Checkmark, only display for selected option.

            Highlighted: "text-white", Not Highlighted: "text-indigo-600"
          -->
              <span
                v-if="value == item.key"
                class="absolute inset-y-0 right-0 flex items-center pr-4"
              >
                <!-- Heroicon name: check -->
                <svg
                  class="w-5 h-5"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clip-rule="evenodd"
                  />
                </svg>
              </span>
            </li>

            <!-- More options... -->
          </ul>
        </div>
      </div>
    </div>
    <!-- <input :value="value" @input="$emit('input', $event.target.value)" id="input" :class="['block w-full px-2 py-1 mt-1 border rounded ring-1 ring-black ring-opacity-5 boder-gray-400 form-input sm:text-sm sm:leading-5', inputClass]" :placeholder="placeholder"> -->
    <div class="text-xs text-gray-600">{{ description }}</div>
  </div>
</template>

<script>
export default {
  props: [
    "description",
    "label",
    "showLabel",
    "placeholder",
    "value",
    "inputClass",
    "options",
    "emptyLabel",
    "noSelect",
    "allowNull",
  ],
  data() {
    return {
      open: false,
    };
  },
  computed: {
    selected() {
      if (!this.value && !this.noSelect) return this.options[0];
      return this.options.find((ev) => ev.key == this.value);
    },
    isOpen() {
      return this.open;
    },
  },
  // created() {
  //   if (this.noSelect) return null;
  //   if (!this.value) {
  //     this.$emit("input", this.options[0]);
  //   } else {
  //     this.select(this.value);
  //     this.$emit("input", this.value);
  //   }
  // },
  mounted() {
    if (this.noSelect) return null;
    if (!this.value) {
      this.$emit("input", this.options[0]);
    } else {
      this.select(this.value);
      // this.$emit("input", this.value);
    }
  },
  methods: {
    select(key) {
      this.open = false;
      this.$emit("input", key);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
