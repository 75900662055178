<template>
  <div>
    <!-- Select para moneda precio base -->
    <w-select
      v-model="value.preferredCurrency"
      label="Moneda"
      name="moneda"
      :options="[
        {
          MediaKeyStatusMap: 'ars',
          label: 'ARS',
        },
        {
          MediaKeyStatusMap: 'usd',
          label: 'USD',
        },
        {
          MediaKeyStatusMap: 'eur',
          label: 'EUR',
        },
      ]"
      class="w-64 mb-2"
    />
    <div class="flex align-center mb-2">
      <w-money
        v-model="value.ars"
        label="Precio en Pesos"
        name="precio en pesos"
        placeholder="100 ARS"
        class="w-64"
        :disabled="value.arsAutomatico"
      />
      <!-- Checkbox -->
      <div class="flex items-center ml-2 mt-6">
        <input
          id="checkbox-1"
          type="checkbox"
          v-model="value.arsAutomatico"
          class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
        />
        <label
          for="checkbox-1"
          class="block ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
        >
          Automático (dólar oficial)
        </label>
      </div>
    </div>
    <div class="flex align-center mb-2">
      <w-money
        v-model="value.usd"
        label="Precio en Dolares"
        name="precio en dolares"
        currency="usd"
        :dolar="dolar"
        placeholder="100 USD"
        class="mb-2 w-64"
        :disabled="value.usdAutomatico"
      />
      <!-- Checkbox -->
      <div class="flex items-center ml-2 mt-6">
        <input
          id="checkbox-1"
          type="checkbox"
          v-model="value.usdAutomatico"
          class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
        />
        <label
          for="checkbox-1"
          class="block ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
        >
          Automático (dólar/euro)
        </label>
      </div>
    </div>
    <div class="flex align-center mb-2">
      <w-money
        v-model="value.eur"
        label="Precio en Euros"
        name="precio en euros"
        currency="eur"
        :dolar="dolar"
        placeholder="100 EUR"
        class="mb-2 w-64"
        :disabled="value.eurAutomatico"
      />
      <!-- Checkbox -->
      <div class="flex items-center ml-2 mt-6">
        <input
          id="checkbox-1"
          type="checkbox"
          v-model="value.eurAutomatico"
          class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
        />
        <label
          for="checkbox-1"
          class="block ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
        >
          Automático (euro/dólar)
        </label>
      </div>
    </div>

    <w-money
      v-model="value.creditos"
      label="Precio en Créditos Orsai (USD)"
      name="precio en créditos orsai"
      currency="usd"
      :dolar="dolar"
      placeholder="100 USD"
      class="mb-2 w-64"
    />
  </div>
</template>

<script>
import axios from "axios";
export default {
  props: {
    value: {
      type: Object,
      default: () => {
        return {
          ars: null,
          creditos: null,
          usd: null,
          arsAutomatico: true,
        };
      },
    },
    rules: {
      type: String,
      default: () => {
        return "required";
      },
    },
  },
  data() {
    return {
      dolar: null,
      show: true,
      error: null,
    };
  },
  computed: {},
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.$emit("input", newValue);
      },
    },
  },
  mounted() {
    this.getCurrencies();
  },
  methods: {
    async getCurrencies() {
      const data = (
        await axios.get(
          "https://prime.exchangerate-api.com/v5/1a013f6ab04b0fd3d0718a67/latest/USD"
        )
      ).data;
      if (data.result != "error")
        this.dolar =
          Math.round(data.conversion_rates["ARS"] * 1.05 * 1.3 * 100) / 100;
      else this.dolar = 155;
    },
  },
};
</script>

<style lang="css" scoped></style>
