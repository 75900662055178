<template>
  <div class="mb-2">
    <div class="flex flex-wrap items-center md:flex-init">
      <div class="flex items-center justify-between w-full space-x-3">
        <div class="w-3/5">
          <label
            for="input"
            :class="{ 'text-red-500': false }"
            class="block text-sm font-medium leading-5 text-gray-700"
            >{{ labelDay }}</label
          >
          <v-date-picker
            :masks="masks"
            :value="initValue"
            @input="$emit('input', $event)"
            mode="dateTime"
            is24hr
          >
            <template v-slot="{ inputValue, inputEvents }">
              <input
                class="px-2 py-1 border rounded focus:outline-none focus:border-blue-300"
                :value="inputValue"
                v-on="inputEvents"
              />
            </template>
          </v-date-picker>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    "placeholder",
    "label-hour",
    "label-day",
    "helper",
    "value",
    "duration",
  ],
  data() {
    return {
      show: false,
      masks: {
        input: "DD-MM-YYYY h:mm A",
      },
      initValue: null,
      // hour: null,
      // error: false,
      // day: null,
      // durationValue: 0,
    };
  },
  computed: {
    // eventDate() {
    //   if (!this.hour) return null;
    //   const date = this.$moment(this.day);
    //   date.hour(this.hour.HH);
    //   date.minute(this.hour.mm);
    //   date.second(0);
    //   return date.toDate();
    // },
  },
  watch: {
    // eventDate(newDate) {
    //   console.log("newDate", newDate);
    //   this.$emit("input", newDate);
    // },
  },
  mounted() {
    console.log("init value", this.value);
    let localDate = this.$moment(this.value).local();
    this.initValue = localDate.toDate();

    // let hour = this.$moment();
    // if (this.value) hour = this.$moment(this.value);
    // this.day = hour.toDate();
    // this.hour = {
    //   HH: hour.format("HH"),
    //   mm: hour.format("mm"),
    // };
    // this.$emit("input", this.$moment(this.eventDate));
    // this.durationValue = this.duration;
  },
  methods: {
    // changeDay(date) {
    //   console.log("changeDay", this.hour);
    //   const value = this.$moment(date);
    //   value.hour(this.hour.HH);
    //   value.minute(this.hour.mm);
    //   this.day = value.toDate();
    //   console.log("this.day", this.day);
    // },
    // changeHour(value) {
    //   this.hour = value;
    // },
  },
};
</script>

<style lang="scss">
.vue__time-picker input {
  @apply block;
  @apply px-2;
  @apply py-1;
  @apply mt-1;
  @apply border;
  @apply rounded;
  @apply ring-1 ring-black ring-opacity-5;
  @apply items-center;
  @apply text-sm;
}
.vc-appearance-none {
  @apply block;
  @apply px-2;
  @apply py-1;
  @apply mt-1;
  @apply border;
  @apply rounded;
  @apply ring-1 ring-black ring-opacity-5;
  @apply items-center;
  @apply text-sm;
}
</style>
