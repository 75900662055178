import { render, staticRenderFns } from "./index.vue?vue&type=template&id=0a79bf96&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vue-automatic-import-loader */
import autoInstallComponents from "!/workspace/node_modules/vue-automatic-import-loader/lib/runtime/autoInstallComponents.js"
import WAuthorSelect from '@/components/WAuthorSelect.vue'
import WCategorySelect from '@/components/WCategorySelect.vue'
import WDateTime from '@/components/WDateTime.vue'
import WHtml from '@/components/WHtml.vue'
import WImageUpload from '@/components/WImageUpload.vue'
import WInput from '@/components/WInput.vue'
import WInputNumber from '@/components/WInputNumber.vue'
import WProductPrice from '@/components/WProductPrice.vue'
import WProductVariant from '@/components/WProductVariant.vue'
import WSelect from '@/components/WSelect.vue'
import WSwitch from '@/components/WSwitch.vue'
import WTags from '@/components/WTags.vue'
import WTextarea from '@/components/WTextarea.vue'
import WTopBar from '@/components/WTopBar.vue'
autoInstallComponents(component, {WAuthorSelect,WCategorySelect,WDateTime,WHtml,WImageUpload,WInput,WInputNumber,WProductPrice,WProductVariant,WSelect,WSwitch,WTags,WTextarea,WTopBar})
