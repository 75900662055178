<template>
  <div class="mb-2">
    <label
      for="input"
      class="block mb-1 text-sm font-medium leading-5 text-gray-700"
      >Autores</label
    >
    <div v-if="options && options.length > 0">
      <multiselect
        ref="authors"
        label="name"
        track-by="key"
        key="authors"
        v-model="selected"
        :multiple="true"
        :options="options"
        placeholder="Autores"
        selected-label=""
        select-label=""
        deselect-label=""
        tag-placeholder="Agregar como nuevo autor"
        :taggable="true"
        @tag="addAuthor"
      >
        <!-- <template slot="placeholder">
          <div class="text-xs">Seleccionar Autores</div>
        </template> -->
      </multiselect>
    </div>
    <div class="text-xs text-gray-600">{{ description }}</div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
var slugify = require('slugify')
export default {
  components: {
    Multiselect,
  },
  props: {
    value: {
      type: [Array],
      default: null,
    },
    label: {
      type: String,
      default: "Autores",
    },
    description: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      options: [{
            key: "hernan-casciari",
            name: "Hernán Casciari"
          }],
      selected: null,
      detected: null,
    };
  },
  watch: {
    selected: {
      deep: true,
      handler(newAuthors) {
        this.$emit("input", newAuthors)
      }
    }
  },
  methods: {
    addAuthor(newAuthor) {
      const tag = {
        name: newAuthor,
        code: slugify(newAuthor, {lower: true, strict: true})
      }
      this.options.push(tag)
      this.selected.push(tag)
    }
  },
  created() {
    if(this.value && this.value.length > 0)
      {
        this.options = this.value;
        this.selected = this.value;
      }

  },
};
</script>

<style lang="css">
.multiselect__input::placeholder {
  @apply text-sm;
}
</style>
