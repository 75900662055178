<template>
  <div class="mb-2">
    <label
      for="input"
      class="block mb-1 text-sm font-medium leading-5 text-gray-700"
      >{{label}}</label
    >
      <multiselect
        ref="items"
        label="name"
        track-by="key"
        key="items"
        v-model="selected"
        :multiple="true"
        :options="options"
        :placeholder="label"
        selected-label=""
        select-label=""
        deselect-label=""
        tag-placeholder="Agregar ítem"
        :taggable="true"
        @tag="addItem"
      >
        <!-- <template slot="placeholder">
          <div class="text-xs">Seleccionar Autores</div>
        </template> -->
      </multiselect>
    <div class="text-xs text-gray-600">{{ description }}</div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
var slugify = require('slugify')
export default {
  components: {
    Multiselect,
  },
  props: {
    value: {
      type: [Array, String],
      default: null,
    },
    label: {
      type: String,
      default: "Ítems",
    },
    description: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      options: [],
      selected: [],
      detected: null,
    };
  },
  watch: {
    selected: {
      deep: true,
      handler(newItem) {
        this.$emit("input", newItem)
      }
    }
  },
  methods: {
    addItem(newItem) {
      const tag = {
        name: newItem,
        code: slugify(newItem, {lower: true, strict: true})
      }
    //   this.options.push(tag)
      this.selected.push(tag)
    }
  },
  created() {
    if(this.value && this.value.length > 0)
      {
          let value = this.value;
        if(typeof this.value === "string")
            value = []

        this.options = value;
        this.selected = value;
      }

  },
};
</script>

<style lang="css">
.multiselect__input::placeholder {
  @apply text-sm;
}
</style>
